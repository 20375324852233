import Helmet from "react-helmet"
import React from "react"
import RawFooter from "@tightrope/footerlinks/rawfooter.js"

export default function ThankYouAmo() {
  return(
    <>
    <Helmet>
     <style type="text/css">{`
    @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap');
    * {
    	font-family: 'Roboto', sans-serif !important;
    }
    section {
    	position: relative;
    	display: flex;
    	justify-content: flex-end;
    	align-items: center;
    	height: 100vh;
    	width: 100%;
    	background: #efefef;
    }
    .thankYouL, .thankYouR {
    	height: 100%;
    	width: 50%;
    }
    .thankYouL {
    	position: relative;
    	display: flex;
    	flex-direction: column;
    	justify-content: center;
    	align-items: flex-start;
    	margin: 0 0 200px 75px;
    }
    .thankYouL img {
    	position: relative;
    	height: 35px;
    	margin: 0 0 8px 0;
    }
    .thankYouR {
    	display: flex;
    	justify-content: flex-end;
    	align-items: center;
    	margin: 0 75px 25px 0;
    }
    .howTo {
    	position: relative;
    	display: flex;
    	flex-direction: column;
    	justify-content: space-between;
    	width: 500px;
    	padding: 0;
    	border-radius: 12px;
    	box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
    	box-sizing: border-box;
    	background: #fff;
    }
    h1 {
    	color: #252525;
    	font-size: 16px;
    	font-weight: 400;
    	line-height: 200%;
    }
    h1 span {
    	display: block;
    	font-size: 48px;
    	font-weight: 700;
    }
    h2 {
    	color: #252525;
    	font-size: 24px;
    	font-weight: 700;
    	margin: 0;
    	padding: 20px 0;
    	text-align: center;
    	line-height: 100%;
    	background: #dcdcdc;
    	border-top-left-radius: 8px;
    	border-top-right-radius: 9px;
    }
    .howTo ul {
    	position: relative;
    	margin: 20px 0;
    	font-size: 20px;
    	padding: 0 20px 0 44px;
    	line-height: 100%;
    }
    .howTo ul li {
    	padding: 0 0 12px 0;
    }
    .howTo ul li img {
    	display: inline;
    	vertical-align: middle;
    	width: 26px;
    	padding: 0 0 3px 0;
    }
    .howTo ul li span {
    	font-weight: 700;
    	font-style: italic;
    }
    .arrow {
    	padding: 0 0 0 16px;
    }
	footer {
		font-family: 'Roboto', sans-serif;
		position:absolute;
		width:100%;
		background:#efefef;
		bottom:0;
		padding:12px 0 0;
	  }
	
	  footer a {
		color:#333;
	  }
	  
	  footer ul {
		margin:0 auto;
		text-align: center;
		display:flex;
		justify-content: center;
		align-items: center;
		color:#333;
		font-size:13px;
		padding:0 0 12px 0;
		list-style:none;
	  }
	  
	  footer ul li {
		padding:0 6px 0 0;
	  }
	  
	  footer ul li:last-child {
		padding:0;
	  }
	  
	  footer ul li:after {
		content: '|';
		padding:0 0 0 6px;
	  }
	  
	  footer ul li:last-child:after {
		content:none;
		padding:0;
	  }

	  @media only screen and (max-width: 1366px) {
		.thankYouL {
		margin: 0 0 200px 20px;
	  }
	  .thankYouR {
		margin: 0 20px 25px 0;
	  }
	}
  
	  @media only screen and (max-width: 992px) {
		section {
			flex-direction:column;
			justify-content:space-evenly;
		}
		.thankYouL {
			height:auto;
			width:auto;
			max-width:96%;
			margin: 0 auto;
		  }
		  .thankYouR {
			height:auto;
			width:auto;
			max-width:96%;
			margin: 0 auto;
		  }
		.arrow {
			display:none;
		}
	  footer ul {
		flex-wrap:wrap;
	}
    
    }
    `}
    </style>
  
    <title>Thank you for downloading</title>
    </Helmet>
    <section>
      <div class="thankYouL">
      <img src="/img/logo.png" alt="Manuals Library Logo" />
      <h1>Thank you for installing<span>Manuals Library</span></h1>
      </div>
      <div class="thankYouR">
      <aside class="howTo">
        <h2>How to Access Manuals Library</h2>
        <ul>
          <li>Look for the Manuals Library Icon <img src="/img/ml-icon.png" alt="Manuals Library Icon" /></li>
          <li><span>Can’t find it?</span> Click the icon <img src="/img/puzzle-icon.png" alt="Puzzle Icon" />  to expand the list of your extensions</li>
        </ul>
      </aside>
        <img class="arrow" src="/img/arrow-up.png" alt="Manuals Library" />
      </div>
    </section>
	<RawFooter></RawFooter>
    </>
  )
}